import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setLoginStatus, setUserDetails } from '../store.js';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    position: 'fixed',
  }));


const useStyles = makeStyles ({
    toolbar:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    typography:{
        textAlign: 'center',
    }
});

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch(); 
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => ({userDetails: state.globalState.userDetails}), shallowEqual);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(setLoginStatus(false));
    dispatch(setUserDetails(null));
    navigate('/');
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <div style={{ display: 'flex', justifyContent:'flex-end', alignItems: 'center' }}>
            <Tooltip title="Change Profile Settings">
              <IconButton onClick={() => navigate("/settings")} size="large">
                  <SettingsIcon/>
              </IconButton>
            </Tooltip>
            <Box sx={{ flexGrow: 0.05 }} />
            <Typography
            variant="h6"
            noWrap
            component="div"
            className={classes.typography}
            >
                {userDetails ? userDetails.email : 'User'}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle/>
            </IconButton>
          </div>
        </Toolbar>
        {renderMenu}
      </AppBar>
  );
}
