import React, { useState } from 'react';
import { List, ListItem, Typography, Box, IconButton } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListItemText from '@mui/material/ListItemText';
import UpdatePassword from './updatePasswordProfile.js';
import UpdateProfile from './updateProfile';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useAlert from '../../alert/useAlert';
import { useNavigate } from 'react-router-dom';
import { setUserDetails, setLoginStatus } from '../../store.js';
import axios from 'axios';


const ManageAccountProfile = ({setOpenDialog}) => {
    const [optionSelected, setOptionSelected] = useState('');
    const { userDetails } = useSelector((state) => ({userDetails: state.globalState.userDetails}), shallowEqual);
    const dispatch = useDispatch(); 
    const { setAlert } = useAlert();
    const navigate = useNavigate();
    

    const accountOptions = [
      'Reset password',
      'Update profile',
      'Delete Account'
    ];

    const handleListItemClick = (option) => {
        setOptionSelected(option);
    }
    
    const deleteUser = async (id) => {
        try {
            const response = await axios.post('https://vzsqbjdvkovxjyrcudpw.supabase.co/functions/v1/delete-user', { userId: id }, 
                { headers: {
                    'Authorization': `Bearer b87682b91161e00727b0e7ff66a33bcc50905db3cb57cd7fe50bbc76f2fb27f1`,
                    'Content-Type': 'application/json',
                    }
                });
            if(response.status === 200){
                dispatch(setLoginStatus(false));
                dispatch(setUserDetails(null));
                navigate('/login');
            }
        } catch (error) {
            setAlert(error.response?.data?.error || 'An error occurred', 'error');
        }
    }
  
    return (
        optionSelected === "Reset password" ? (
            <UpdatePassword setOptionSelected={setOptionSelected}/>
        )
        :
        optionSelected === "Update profile" ? (
            <UpdateProfile setOptionSelected={setOptionSelected} userid={userDetails.id} useremail={userDetails.email} username={userDetails.name} userpracticetype={userDetails.practicetype}/>
        )
        :
        <div>
            <Typography sx={{fontWeight: 'bold'}} noWrap variant="h6" gutterBottom>
            Manage Account and Profile
            </Typography>
            <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '10px', padding: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <List>
                {accountOptions.map((option) => (
                    option !== 'Delete Account' ? (
                        <div>
                            <ListItem button onClick={(event) => handleListItemClick(option)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '50px' }}>
                                <IconButton sx={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center',}} >
                                    <AccountCircle />
                                    <Typography noWrap variant="body1" sx={{marginLeft: '20px'}}>{option}</Typography>
                                </IconButton>
                                <IconButton>
                                    <KeyboardArrowRightIcon />
                                </IconButton>
                            </ListItem>
                            <Divider variant="middle" component="li" />
                        </div>
                    )
                    :
                    <div>
                        <ListItem button alignItems="flex-start" sx={{marginLeft: '10px', cursor:'pointer'}} onClick={(e) => { e.preventDefault(); setOpenDialog({open: true, content: "Are you sure you want to delete this user?", confirmAction: () => deleteUser(userDetails.id)})}}>
                            <ListItemText
                            primary={<Typography variant="body1" style={{ color: 'red' }}>{option}</Typography>}
                            secondary="If you delete your account, all of the associated data will be permanently deleted."
                            />
                        </ListItem>
                    </div>
                ))}
                </List>
            </Box>
        </div>
    );
  };

export default ManageAccountProfile;