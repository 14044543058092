import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../../supabaseClient.js';
import { supabaseService } from '../../supabaseService.js';
import Grid from '@mui/material/Grid2';
import { Container, IconButton, Typography, TextField, Stack, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import { setLoginStatus, setUserDetails } from '../../store.js';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TikcareLogo } from '../../assets/svg/TikcareLogo.svg';
import useAlert from '../../alert/useAlert.js';
import PopupMenu from '../MedicalScribe/PopupMenu/PopupMenu.js';
import Loader from '../../loader.js';

const SignupScreen = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [signupDetailsSubmitted, setSignupDetailsSubmitted] = useState(false);
  const [tosCheckbox, setTosCheckbox] = useState(false);
  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('signup');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const { setAlert } = useAlert();
  const hasMounted = useRef(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  useEffect(() => {
    if (hasMounted.current) { return; }
    dispatch(setLoginStatus(false));
    dispatch(setUserDetails(null));
    hasMounted.current = true;
  }, []);

  const handleSignUp = async () => {
    if(!newPassword || !confirmPassword || !email || passwordError || confirmPasswordError) {
      setAlert('Please enter all the required fields properly', 'error');
      return;
    }
    if(!tosCheckbox || !privacyPolicyCheckbox) {
      setAlert('Please agree to the Terms & Conditions and Privacy Policy', 'warning');
      return;
    }
    if(newPassword !== confirmPassword) {
      setAlert('Passwords do not match', 'error');
      return;
    } 
    setLoading(true);
    const { data: { users }, error: listUsersError } = await supabaseService.auth.admin.listUsers()
    if (listUsersError) {
      console.error('Error fetching all users:', listUsersError.message);
      setLoading(false);
      setAlert('Unable to create an account. Please try again later', 'error');
      return;
    } else {
      const userExists = users.find(user => user.email === email);
      if (userExists) {
        setLoading(false);
        setAlert('User with this email already exists.', 'error');
        return;
      }
    }

    const { error } = await supabase.auth.signUp({
      email: email,
      password: newPassword,
      options: {
        emailRedirectTo: process.env.REACT_APP_VERIFY_EMAIL,
        // emailRedirectTo: 'https://localhost:3000/emailVerify',
      },
    });

    if (error) {
      setAlert(error.message, 'error');
    } else {
      setCurrentScreen('verificationLink');
    }
    setLoading(false);
  };

  const validatePassword = (password) => {
    // Regex for at least one letter, one special character, and minimum 6 characters
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    if (!strongPasswordRegex.test(password)) {
      setPasswordError('Password must contain at least 6 characters, one letter, and one special character.');
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = (password) => {
    // Regex for at least one letter, one special character, and minimum 6 characters
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    if (!strongPasswordRegex.test(password)) {
      setConfirmPasswordError('Password must contain at least 6 characters, one letter, and one special character.');
    } else {
      setConfirmPasswordError('');
    }
  };

  return (
    <Container disableGutters maxWidth={false}> 
      <Grid container spacing={2} sx={{ minHeight: '100vh', minWidth: '100vw', marginTop: -3 }}>
      <Grid size={12} sx={{ backgroundColor: '#B9F4F2', height: '90px', padding: '15px 10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <TikcareLogo onClick={() => { navigate('/'); }} style={{cursor: 'pointer'}}/>
        </Grid>
        {
            currentScreen === 'signup' ?
            <Grid size={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
            <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" style={{backgroundColor: '#f5f5f5', padding: '30px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
                <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '30px'}}>Sign Up</Typography>
                <Typography variant="body1"> Already have an account? <span onClick={() => { navigate('/') }} style={{ cursor: 'pointer', color: '#29C0BB' }}>Log in</span> </Typography>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  variant="outlined"
                  focused
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email" 
                  sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
                  error={signupDetailsSubmitted && !email}
                  helperText={signupDetailsSubmitted && !email && "Email is required"}
                />
                <TextField
                  required
                  fullWidth
                  label="Create a password"
                  variant="outlined"
                  focused
                  value={newPassword}
                  onChange={(e) => { setNewPassword(e.target.value); validatePassword(e.target.value); }}
                  placeholder="Create a password" 
                  type={showPassword ? "text" : "password"}
                  sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
                  error={(signupDetailsSubmitted && !newPassword) || passwordError}
                  helperText={((signupDetailsSubmitted && !newPassword) || passwordError) ? passwordError ?? "Enter a password" : ""}
                  slotProps={{
                    input: {
                      endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {setShowPassword(!showPassword); }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                      ),
                    }
                  }}
                />
                <TextField
                  required
                  fullWidth
                  label="Confirm password"
                  variant="outlined"
                  focused
                  value={confirmPassword}
                  onChange={(e) => { setConfirmPassword(e.target.value); validateConfirmPassword(e.target.value); }}
                  placeholder="Confirm password" 
                  type={showConfirmPassword ? "text" : "password"}
                  sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
                  error={(signupDetailsSubmitted && !confirmPassword) || confirmPasswordError}
                  helperText={((signupDetailsSubmitted && !confirmPassword) || confirmPasswordError) ? confirmPasswordError ?? "Enter a password" : ""}
                  slotProps={{
                    input: {
                      endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                      ),
                    }
                  }}
                />
                <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                    <input type="checkbox" checked={tosCheckbox} onChange={() => setTosCheckbox(!tosCheckbox)} />
                    <Typography variant="body1">I agree to the <span style={{ cursor: 'pointer', color: '#29C0BB', textDecoration: 'underline' }}><a href="https://tikcare.co/terms-of-use/" target="_blank" rel="noreferrer">Terms & Conditions</a></span> </Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                    <input type="checkbox" checked={privacyPolicyCheckbox} onChange={() => setPrivacyPolicyCheckbox(!privacyPolicyCheckbox)} />
                    <Typography variant="body1">I agree to the <span style={{ cursor: 'pointer', color: '#29C0BB', textDecoration: 'underline' }}><a href="https://tikcare.co/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a></span> </Typography>
                </Stack>
                <Button onClick={() => { setSignupDetailsSubmitted(true); handleSignUp(); }} variant="outlined" sx={{width: '100%', height: '50px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
                  <Typography variant="button" sx={{color: '#FFFFFF', fontSize: '15px'}}>Sign Up</Typography>
                </Button>
              </Stack>
            </Grid>
            :
            currentScreen === 'verificationLink' ?
            <Grid size={12} sx={{padding:'50px', display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" style={{backgroundColor: '#f5f5f5', padding: '30px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
                <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '30px'}}>A verification link has been sent to your email. Please verify your email to login.</Typography>
                <Typography variant="body1">Remember your password? <span onClick={() => navigate('/')} style={{ cursor: 'pointer', color: '#29C0BB' }}>Login</span> </Typography>
                </Stack>
            </Grid>  
            : null     
        }
        {
        loading && (
          <PopupMenu>
            <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
              <Typography variant="h6">Creating Account</Typography>
              <Loader />
            </Stack>
          </PopupMenu> 
          ) 
        }
      </Grid>
    </Container>
  );
};

export default SignupScreen;

