import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Stack
} from "@mui/material";
import { Delete, FileCopy, Save, ArrowUpward, ArrowDownward, ArrowBack as ArrowBackIcon, } from "@mui/icons-material";
import Grid from '@mui/material/Grid2';
import { ReactComponent as Generate} from '../../assets/svg/generate.svg';
import PopupMenu from "../MedicalScribe/PopupMenu/PopupMenu";
import Loader from "../../loader";
import levenshtein from 'fast-levenshtein';


const SummaryViewer = ({ patient, summary, prompt, files, onBack, uploadFiles, handleRetrieveText, retrievedData, setPrompt }) => {
  const [lengthOption, setLengthOption] = useState("");
  const [formatOption, setFormatOption] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [numberOfHighlights, setNumberOfHighlights] = useState(0); 
  const [fileHighlightCounts, setFileHighlightCounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [retrieving, setRetrieving] = useState(false);
  const [content, setContent] = useState("");
  const hasMounted = useRef(false);
  const [highlightingProgress, setHighlightingProgress] = useState(false);

  const handlePreviousHighlight = () => {
    if(numberOfHighlights === 1) {
      scrollIntoView();
      return;
    }
    setHighlightedIndex(highlightedIndex > 1 ? highlightedIndex - 1 : numberOfHighlights);
  };

  const handleNextHighlight = () => {
    if(numberOfHighlights === 1) {
      scrollIntoView();
      return;
    }
    setHighlightedIndex(highlightedIndex < numberOfHighlights ? highlightedIndex + 1 : 1);
  };

  const rightContentRef = useRef(null);

  const handleLengthChange = (event, newLength) => {
    if (newLength) {
      setLengthOption(newLength);
      setPrompt(""); 
    }
  };

  const handleFormatChange = (event, newFormat) => {
    if (newFormat) { 
      setFormatOption(newFormat);
      setPrompt(""); 
    }
  };

  const handleMouseUp = () => {
    const selection = window.getSelection().toString();
    if (selection) {
        setHighlightedText(selection);
    } else{
        setHighlightedText("");
    }
  };

  useEffect(() => {
    if (hasMounted.current) { return; }
    setLoading(true);
    const fetchSummary = async () => {
      await uploadFiles();
      setLoading(false);
    };
    fetchSummary(); 
    hasMounted.current = true;
  }, []);

  const regenerateSummary = () => {
    setHighlightedText("");
    setNumberOfHighlights(0);
    setFileHighlightCounts(getFileHighlightCounts([]));
    setContent(files[currentFileIndex]?.content || "");
    setLoading(true);
    const fetchSummary = async () => {
      await uploadFiles(!prompt ? lengthOption : "", !prompt ? formatOption : "");
      setLoading(false);
    };
    fetchSummary(); 
  }


  useEffect(() => {
    const retrieveText = async () => {
      // console.log("the highlighted text is: ", highlightedText);
      const retrievedText = await handleRetrieveText(highlightedText);
      setRetrieving(false);
      if(retrievedText.length > 0) {
        highlightContent(files[currentFileIndex]?.content || "", retrievedText);
        setFileHighlightCounts(getFileHighlightCounts(retrievedText));
        settingNumberOfHighlights(retrievedText);
      }
    }
    if(highlightedText !== "") {
      setHighlightingProgress(true);
      setRetrieving(true);
      retrieveText();
    } else {
      setNumberOfHighlights(0);
      setFileHighlightCounts(getFileHighlightCounts([]));
    }
  }, [highlightedText]);


  useEffect(() => {
    scrollIntoView();
  }, [highlightedIndex]);


  useEffect(() => {
    highlightContent(files[currentFileIndex]?.content || "", retrievedData);
    setFileHighlightCounts(getFileHighlightCounts(retrievedData));
    settingNumberOfHighlights(retrievedData);
  }, [files, currentFileIndex]);

  useEffect(() => {
    if(!highlightingProgress && numberOfHighlights > 0) {
      setHighlightedIndex(1);
      scrollIntoView();
    }
  }, [highlightingProgress]);

  const settingNumberOfHighlights = (retrievedText) => {
    if (files[currentFileIndex]?.content && highlightedText !== "") {
      let count = 0;
      const highlightedSentences = new Set();
      const sentences = files[currentFileIndex].content.split('<p>').map(item => item.split('</p>')[0]).filter(item => item.trim() !== '').join("<br />").split('<br />').filter(item => item.trim() !== '');
      sentences.forEach((sentence) => {
        const normalizedSentence = stripHTML(sentence).replace(/\s+/g, " ").trim();

        retrievedText.forEach((text) => {
          if (highlightedSentences.has(normalizedSentence)) return;
          const normalizedText = text.replace(/\s+/g, " ").trim();
          const distance = levenshtein.get(normalizedSentence, normalizedText, { useCollator: true });
          const maxLength = Math.max(normalizedSentence.length, normalizedText.length);
          const similarity = 1 - distance / maxLength;

          if (similarity >= 0.55 || normalizeString(normalizedSentence).includes(normalizeString(highlightedText))) {
            count++;
            highlightedSentences.add(normalizedSentence); 
          }
        });
      });
      setNumberOfHighlights(count);
    } else {
      setNumberOfHighlights(0);
    }
  }

  const stripHTML = (html) => new DOMParser().parseFromString(html, "text/html").body.textContent || "";

  const getFileHighlightCounts = (retrievedText) => {
    return files.map((file) => {
      let count = 0;
      const highlightedSentences = new Set();
      const sentences = file.content.split('<p>').map(item => item.split('</p>')[0]).filter(item => item.trim() !== '').join("<br />").split('<br />').filter(item => item.trim() !== '');
      sentences.forEach((sentence) => {
        const normalizedSentence = stripHTML(sentence).replace(/\s+/g, " ").trim();
        retrievedText.forEach((text) => {
          if (highlightedSentences.has(normalizedSentence)) return;
          const normalizedText = text.replace(/\s+/g, " ").trim();
          const distance = levenshtein.get(normalizedSentence, normalizedText, { useCollator: true });
          const maxLength = Math.max(normalizedSentence.length, normalizedText.length);
          const similarity = 1 - distance / maxLength;
          // console.log("the normalized sentence is: ", normalizedSentence, "and the normalized text is: ", normalizedText, "and the similarity is: ", similarity);
          if (similarity >= 0.55 || normalizeString(normalizedSentence).includes(normalizeString(highlightedText))) {
            count++;
            highlightedSentences.add(normalizedSentence); 
          }
        });
      });
      // console.log("Highlight count for file:", count);
      return { name: file.name, count: highlightedText ? count : 0 };
    });
  };

  const scrollIntoView = () => {
    // console.log("the highlighted index for  scrolling into view is: ", highlightedIndex);
    const contentElement = rightContentRef.current;
    const highlightedElement = contentElement.querySelector(`.highlighted-${highlightedIndex}`);
    if (highlightedElement) {
      highlightedElement.scrollIntoView({
        behavior: "smooth",
        block:'nearest' 
      });
    }
  }

  const handleFileChange = (name) => {
    const index = files.findIndex((file) => file.name === name);
    if (index >= 0) {
      setCurrentFileIndex(index);
    }
  }

  const highlightContent = (content, retrievedText) => {
    let currentHighlightIndex = 0;
    const highlightedSentences = new Set();
    const sentences = content.split('<p>').map(item => item.split('</p>')[0]).filter(item => item.trim() !== '').join("<br />").split('<br />').filter(item => item.trim() !== '');
    const splitContent = sentences.map((sentence, idx) => {
      let isHighlighted = false;
      const normalizedSentence = stripHTML(sentence).replace(/\s+/g, " ").trim();

      retrievedText.forEach((text) => {
        if (highlightedSentences.has(normalizedSentence)) return;
        const normalizedText = text.replace(/\s+/g, " ").trim();
        const distance = levenshtein.get(normalizedSentence, normalizedText, { useCollator: true });
        const maxLength = Math.max(normalizedSentence.length, normalizedText.length);
        const similarity = 1 - distance / maxLength;
        if (similarity >= 0.55 || normalizeString(normalizedSentence).includes(normalizeString(highlightedText))) {
          isHighlighted = true;
          highlightedSentences.add(normalizedSentence); 
        }
      });
      if (isHighlighted) {
        currentHighlightIndex++;
      }
      if (isHighlighted && highlightedText !== "") {
        const highlightColor = generateHighlightColor(currentHighlightIndex);
        return `<span class="highlighted-${currentHighlightIndex}" style="background-color: ${highlightColor}">
                  ${processHTML(sentence)}${idx < sentences.length - 1 ? "</p><p>" : ""}
                </span>`;
      }
      return `${sentence}${idx < sentences.length - 1 ? "</p><p>" : ""}`;
    });
    setContent(splitContent.join(""));
    setHighlightingProgress(false);
  };

  const generateHighlightColor = (index) => {
    const hue = (index * 137.508) % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };

  const processHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
  
    const cleanNode = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName.toLowerCase() === "p") {
          const fragment = document.createDocumentFragment();
          const br = document.createElement("br");
          fragment.appendChild(br);
          node.childNodes.forEach((child) => {
            fragment.appendChild(cleanNode(child));
          });
          return fragment;
        } else if (node.tagName.toLowerCase() === "strong") {
          // Retain <strong> tags
          const strong = document.createElement("strong");
          node.childNodes.forEach((child) => {
            strong.appendChild(cleanNode(child));
          });
          const fragment = document.createDocumentFragment();
          fragment.appendChild(strong);
          fragment.appendChild(document.createTextNode(" "));
          return fragment;
        } else {
          // Remove all other tags but keep their content
          return document.createTextNode(node.textContent);
        }
      }
      return document.createTextNode(node.textContent);
    };
  
    const body = doc.body.cloneNode(true); // Clone to avoid modifying the original
    const fragment = document.createDocumentFragment();
    body.childNodes.forEach((child) => {
      fragment.appendChild(cleanNode(child));
    });
  
    const div = document.createElement("div");
    div.appendChild(fragment);
  
    return div.innerHTML.replace(/\s+/g, " "); 
  };

  const normalizeString = (str) => str.replace(/\s+/g, "").replace(/[^\w\s]/g, "").toLowerCase();

  return (
    <Grid container spacing={2} sx={{ flexDirection: 'column' }}>
      <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', mb: 2, }}>
        <Tooltip title="Back to Upload Screen">
          <IconButton onClick={onBack}>
              <ArrowBackIcon />
              <Typography variant="h6" sx={{color: "#29C0BB", marginLeft: '5px'}}>Back</Typography>
          </IconButton>
        </Tooltip>
        <Typography variant="h5" sx={{fontWeight: 'bold', ml: 2}}>{`${patient}'s Summary`}</Typography>
      </Grid>
      <Grid container spacing={2} sx={{ flexDirection: { xs: "column", md: "row"}, paddingBottom: "20px",}}>
        <Grid
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            padding: 2,
            marginRight: 2,
            borderRadius: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between" sx={{mb: 2}}>
            <Typography variant="h6" sx={{color: "#29C0BB", fontWeight: 'bold'}}>Summary</Typography>
            <Stack direction="row" spacing={1}>
                <Tooltip title="Delete Summary">
                  <IconButton onClick={() => { }}>
                    <Delete/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy Summary">
                  <IconButton component="label" onClick={() => {}}>
                    <FileCopy />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save Summary">
                  <IconButton onClick={() => {}}>
                    <Save />
                  </IconButton>
                </Tooltip>
            </Stack>
          </Stack>

          <TextField
            label="Generated Summary"
            multiline
            maxRows={10}
            value={summary}
            fullWidth
            sx={{ mb: 4, backgroundColor: '#f5f5f5' }} 
            onMouseUp={() => { handleMouseUp(); }} 
          />
          <TextField label="Prompt Used" multiline rows={3} value={prompt ?? ''} onChange={(e) => setPrompt(e.target.value)} fullWidth sx={{ mb: 2 }} />

          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Length
          </Typography>
          <ToggleButtonGroup
            value={lengthOption}
            exclusive
            onChange={handleLengthChange}
            sx={{ mb: 2 }}
          >
            <ToggleButton sx={{"&.Mui-selected": { backgroundColor: "#29C0BB", color: "#fff", "&:hover": { backgroundColor: "#29C0BB" }},}} value="detailed">Detailed</ToggleButton>
            <ToggleButton sx={{"&.Mui-selected": { backgroundColor: "#29C0BB", color: "#fff", "&:hover": { backgroundColor: "#29C0BB"}},}} value="concise"> Concise</ToggleButton>
          </ToggleButtonGroup>

          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Format
          </Typography>
          <ToggleButtonGroup
            value={formatOption}
            exclusive
            onChange={handleFormatChange}
            sx={{ mb: 2 }}
          >
            <ToggleButton sx={{"&.Mui-selected": { backgroundColor: "#29C0BB", color: "#fff", "&:hover": { backgroundColor: "#29C0BB" }},}}  value="bulletPoints">Bullet Points</ToggleButton>
            <ToggleButton sx={{"&.Mui-selected": { backgroundColor: "#29C0BB", color: "#fff", "&:hover": { backgroundColor: "#29C0BB" }},}} value="paragraphs">Paragraphs</ToggleButton>
          </ToggleButtonGroup>

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button onClick={() => regenerateSummary()} variant="outlined" sx={{borderRadius: 30, backgroundColor: '#29C0BB', border: 0, width: '150px',}}>
              <IconButton sx={{ padding: 0, marginRight: '5px', backgroundColor: '#fff' }}>
                <Generate />
              </IconButton>
              <Typography variant="button" sx={{color: '#FFFFFF'}}>Regenerate</Typography>
            </Button>
          </div>
        </Grid>

        {/* Right Window */}
        <Grid
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            padding: 2,
            borderRadius: 2,
          }}
          ref={rightContentRef} 
        >
          <Typography variant="h6" sx={{color: "#29C0BB", fontWeight: 'bold'}}>Files</Typography>
          <Stack direction="row" justifyContent="space-between" sx={{mb: 2, mt: 2}}>
            <Typography variant="h6" 
              sx={{mr: 3,
                maxWidth: "70%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                }}>
              {files[currentFileIndex]?.name || "No File Selected"}
            </Typography>
            <div sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Move to previous source">
                <IconButton onClick={() => {handlePreviousHighlight()} }>
                    <ArrowUpward />
                </IconButton>
              </Tooltip>
              <Tooltip title="Move to next source">
                <IconButton onClick={() => {handleNextHighlight()} }>
                    <ArrowDownward />
                </IconButton>
              </Tooltip>
            </div>
          </Stack>

          <Box sx={{ mb: 2 }}>
            {fileHighlightCounts.map(({ name, count }, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  marginBottom: "4px",
                  backgroundColor: count > 0 ? "rgba(0, 128, 128, 0.1)" : '#f5f5f5',
                  cursor: 'pointer'
                }}
                onClick={() => { handleFileChange(name) ;}}
              >
                <Typography               
                sx={{
                  maxWidth: "70%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                  {name}
                </Typography>
                <Typography variant="caption">
                  {count > 0 ? `${count} sources` : "No sources"}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              maxWidth: "100%",
              maxHeight: "500px",
              padding: "16px",
              overflow: "auto",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Box>

        </Grid>
      </Grid>
      {
        (loading || retrieving) &&
        <PopupMenu>
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
            <Typography variant="h6">{loading ? 'Generating Summary' : 'Retrieving Text'}</Typography>
            <Loader />
          </Stack>
        </PopupMenu>
      }
    </Grid>
  );
};

export default SummaryViewer;
