import * as React from 'react';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme, bgcolor, width }) => ({
    position: 'relative',
    color: '#000',
    borderRadius: 30,
    backgroundColor: bgcolor,
    '&:hover': {
      backgroundColor: "#E4E7EB",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: width,
    [theme.breakpoints.up('sm')]: {
      width: width,
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  }));
  
  const StyledInputBase = styled(InputBase)(({ theme, width }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
  }));

export default function SearchBar(props) {
    return (
        <Search bgcolor={props.bgcolor} width={props.width}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                width={props.width}
                placeholder={props.placeholder}
                inputProps={{ 'aria-label': 'search' }}
                value={props.value}
                onChange={props.onChange}
            />
        </Search>
    );
}