import React, { useState } from 'react';
import { supabase } from '../../supabaseClient.js';
import Grid from '@mui/material/Grid2';
import { IconButton, Typography, MenuItem, Select, FormControl, InputLabel, TextField, Stack, Button } from '@mui/material';
import useAlert from '../../alert/useAlert.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setUserDetails } from '../../store.js';
import { useDispatch } from 'react-redux';

const UpdateProfile = ({setOptionSelected, userid, useremail, username, userpracticetype}) => {
  const [name, setName] = useState(username);
  const [practiceType, setPracticeType] = useState(userpracticetype);
  const [profileSubmitted, setProfileSubmitted] = useState(false);
  const { setAlert } = useAlert();
  const dispatch = useDispatch(); 

  const handleProfileSubmit = async () => {
    if(!name) {
      setAlert('Please enter all the required fields properly', 'error');
      return;
    }
    const { error } = await supabase.from('useraccounts').update({ name:  name, practicetype: practiceType}).eq('id', userid);
    if (error){
        setAlert(error.message, 'error');
    } 
    else {
        dispatch(setUserDetails({ id: userid, email: useremail, name: name, practicetype: practiceType }));
        setAlert("Profile updated successfully!", 'success');
    }
  };

  const handlePracticeTypeChange = (event) => {
    setPracticeType(event.target.value);
  };

  return (
    <div>
        <Grid container spacing={2}>
          <Grid size={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
          <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center">
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
                <Typography sx={{fontWeight: 'bold'}} noWrap variant="h6" gutterBottom>Update Profile</Typography>
                <IconButton onClick={() => {setOptionSelected('')}}>
                    <ArrowBackIcon />
                    <Typography variant="h6" sx={{color: "#29C0BB", marginLeft: '5px'}}>Back</Typography>
                </IconButton>
            </Stack>
            <TextField
              required
              fullWidth
              label="Your name"
              variant="outlined"
              focused
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name" 
              sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
              error={profileSubmitted && !name}
              helperText={profileSubmitted && !name && "Name is required"}
              />
              <FormControl fullWidth>
                <InputLabel id="profile-label">Profile</InputLabel>
                <Select
                  labelId="profile-label"
                  id="profile"
                  value={practiceType}
                  label="Profile"
                  onChange={handlePracticeTypeChange}
                  sx={{ '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#29C0BB' } }}
                >
                  <MenuItem value="General Practitioner">General Practitioner</MenuItem>
                  <MenuItem value="Dentist">Dentist</MenuItem>
                  <MenuItem value="Therapist">Therapist</MenuItem>
                  <MenuItem value="Nutritionist">Nutritionist</MenuItem>
                  <MenuItem value="Obstetrics & Gynaecology">Obstetrics & Gynaecology</MenuItem>
                  <MenuItem value="Dermatology & Venereology">Dermatology & Venereology</MenuItem>
                  <MenuItem value="Anaesthesiology">Anaesthesiology</MenuItem>
                  <MenuItem value="Anatomical Pathology">Anatomical Pathology</MenuItem>
                  <MenuItem value="Cardiology">Cardiology</MenuItem>
                  <MenuItem value="Cardiothoracic Surgery">Cardiothoracic Surgery</MenuItem>
                  <MenuItem value="Chinese Medicine">Chinese Medicine</MenuItem>
                  <MenuItem value="Clinical Microbiology & Infection">Clinical Microbiology & Infection</MenuItem>
                  <MenuItem value="Clinical Oncology">Clinical Oncology</MenuItem>
                  <MenuItem value="Clinical Psychology">Clinical Psychology</MenuItem>
                  <MenuItem value="Community Medicine">Community Medicine</MenuItem>
                  <MenuItem value="Counselling Psychology">Counselling Psychology</MenuItem>
                  <MenuItem value="Dentistry">Dentistry</MenuItem>
                  <MenuItem value="Dietetics">Dietetics</MenuItem>
                  <MenuItem value="Emergency Medicine">Emergency Medicine</MenuItem>
                  <MenuItem value="Endocrinology - Diabetes & Metabolism">Endocrinology - Diabetes & Metabolism</MenuItem>
                  <MenuItem value="Family Medicine">Family Medicine</MenuItem>
                  <MenuItem value="Forensic Pathology">Forensic Pathology</MenuItem>
                  <MenuItem value="Gastroenterology & Hepatology">Gastroenterology & Hepatology</MenuItem>
                  <MenuItem value="General Surgery">General Surgery</MenuItem>
                  <MenuItem value="Geriatric Medicine">Geriatric Medicine</MenuItem>
                  <MenuItem value="Gynaecological Oncology">Gynaecological Oncology</MenuItem>
                  <MenuItem value="Haematology & Haematological Oncology">Haematology & Haematological Oncology</MenuItem>
                  <MenuItem value="Immunology & Allergy">Immunology & Allergy</MenuItem>
                  <MenuItem value="Infectious Disease">Infectious Disease</MenuItem>
                  <MenuItem value="Internal Medicine">Internal Medicine</MenuItem>
                  <MenuItem value="Medical Oncology">Medical Oncology</MenuItem>
                  <MenuItem value="Nephrology">Nephrology</MenuItem>
                  <MenuItem value="Neurology">Neurology</MenuItem>
                  <MenuItem value="Neurosurgery">Neurosurgery</MenuItem>
                  <MenuItem value="Nuclear Medicine">Nuclear Medicine</MenuItem>
                  <MenuItem value="Occupational Medicine">Occupational Medicine</MenuItem>
                  <MenuItem value="Ophthalmology">Ophthalmology</MenuItem>
                  <MenuItem value="Oral & Maxillofacial Surgery">Oral & Maxillofacial Surgery</MenuItem>
                  <MenuItem value="Orthopaedics & Traumatology">Orthopaedics & Traumatology</MenuItem>
                  <MenuItem value="Otorhinolaryngology">Otorhinolaryngology</MenuItem>
                  <MenuItem value="Paediatric Immunology, Allergy and Infectious Diseases">Paediatric Immunology, Allergy and Infectious Diseases</MenuItem>
                  <MenuItem value="Paediatric Surgery">Paediatric Surgery</MenuItem>
                  <MenuItem value="Paediatrics">Paediatrics</MenuItem>
                  <MenuItem value="Pain Medicine">Pain Medicine</MenuItem>
                  <MenuItem value="Pathology">Pathology</MenuItem>
                  <MenuItem value="Periodontist">Periodontist</MenuItem>
                  <MenuItem value="Physical Therapy">Physical Therapy</MenuItem>
                  <MenuItem value="Plastic Surgery">Plastic Surgery</MenuItem>
                  <MenuItem value="Psychiatry">Psychiatry</MenuItem>
                  <MenuItem value="Radiology">Radiology</MenuItem>
                  <MenuItem value="Rehabilitation">Rehabilitation</MenuItem>
                  <MenuItem value="Reproductive Medicine">Reproductive Medicine</MenuItem>
                  <MenuItem value="Respiratory Medicine">Respiratory Medicine</MenuItem>
                  <MenuItem value="Rheumatology">Rheumatology</MenuItem>
                  <MenuItem value="Specialist in Prosthodontics">Specialist in Prosthodontics</MenuItem>
                  <MenuItem value="Urogynaecology">Urogynaecology</MenuItem>
                  <MenuItem value="Urology">Urology</MenuItem>
                  <MenuItem value="Vascular Surgery">Vascular Surgery</MenuItem>
                </Select>
              </FormControl>
            <Button onClick={() => { setProfileSubmitted(true); handleProfileSubmit();  }} variant="outlined" sx={{width: '100%', height: '50px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
              <Typography variant="button" sx={{color: '#FFFFFF', fontSize: '15px'}}>Submit</Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateProfile;

