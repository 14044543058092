import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Paper,
  TextField,
  Stack,
  IconButton
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PopupMenu from "../MedicalScribe/PopupMenu/PopupMenu";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector, shallowEqual } from "react-redux";
import { supabase } from "../../supabaseClient.js";


const SectionsSettings = () => {
  const [isPopupTemplate, setPopupTemplate] = useState(false);
  const [newHeading, setNewHeading] = useState("");
  const [sections, setSections] = useState([]);

  const { userDetails } = useSelector((state) => ({userDetails: state.globalState.userDetails}), shallowEqual);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const { data, error } = await supabase
          .from("consultationheadings")
          .select("headings")
          .eq("userID", userDetails.id)
          .single();

        if (error) {
          console.error("Error fetching data:", error.message);
          return;
        }

        if (data && data.headings) {
          setSections(data.headings.headings);
        }
      } catch (err) {
        console.error("Unexpected error:", err);
      }
    };

    fetchSections();
  }, []);

  const setInstruction = async (index, type) => {
    const updatedSections = [...sections];
    updatedSections[index].settingInstruction = type;
    if(type === false){
      const { error } = await supabase.from("consultationheadings").update({ headings: { headings: updatedSections } }).eq("userID", userDetails.id);
      if (error) {
        console.error("Error updating style:", error);
      }
    }
    setSections(updatedSections);
  }

  const handleStyleChange = async (index, newStyle) => {
    const updatedSections = [...sections];
    updatedSections[index].style = newStyle;
    const { error } = await supabase.from("consultationheadings").update({ headings: { headings: updatedSections } }).eq("userID", userDetails.id);
    if (error) {
      console.error("Error updating style:", error);
    }
    setSections(updatedSections);
  };

  const handleHideToggle = async (index) => {
    const updatedSections = [...sections];
    updatedSections[index].hidden = !updatedSections[index].hidden;
    const isCurrentlyHidden = updatedSections[index].hidden;
    const [unhiddenSection] = updatedSections.splice(index, 1);
    if (!isCurrentlyHidden) {
      updatedSections.unshift(unhiddenSection);
    } else {
      updatedSections.push(unhiddenSection);
    }
    const { error } = await supabase.from("consultationheadings").update({ headings: { headings: updatedSections } }).eq("userID", userDetails.id);
    if (error) {
      console.error("Error updating style:", error);
    }
    setSections(updatedSections);
  };

  const handleAddInstruction = (index, value) => {
    if(value === "") {
      setInstruction(index, true);
    }
    const updatedSections = [...sections];
    updatedSections[index].customInstructions = value;
    setSections(updatedSections);
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;
    const reorderedSections = Array.from(sections);
    const [moved] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, moved);
    const { error } = await supabase.from("consultationheadings").update({ headings: { headings: reorderedSections } }).eq("userID", userDetails.id);
    if (error) {
      console.error("Error updating style:", error);
    }
    setSections(reorderedSections);
  };

  const handleAddNewHeading = async () => {
    const newSection = {
      title: newHeading,
      style: "Bullet points",
      hidden: false,
      customInstructions: "",
      settingInstruction: false
    };
    const { error } = await supabase.from("consultationheadings").update({ headings: { headings: [newSection, ...sections] }}).eq("userID", userDetails.id);
    if (error) {
      console.error("Error updating heading in database:", error.message);
      return;
    }
    setSections((prev) => [newSection, ...prev]);
    setPopupTemplate(false);
    setNewHeading("");
  };

  const handleDeleteSection = async (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    const { error } = await supabase.from("consultationheadings").update({ headings: { headings: updatedSections }}).eq("userID", userDetails.id);
    if (error) {
      console.error("Error updating heading in database:", error.message);
      return;
    }
    setSections(updatedSections);
  };

  const handleResetSection = async () => {
    const defaultHeadings = [
      "SUBJECTIVE",
      "OBJECTIVE",
      "ASSESSMENT",
      "PLAN",
      "HISTORY OF PRESENT ILLNESS",
      "PAST MEDICAL HISTORY",
      "PAST SURGICAL HISTORY",
      "PAST OBSTETRIC HISTORY",
      "FAMILY HISTORY",
      "SOCIAL HISTORY",
      "ALLERGIES",
      "CURRENT MEDICATIONS",
      "IMMUNIZATIONS",
      "VITALS",
      "LAB RESULTS",
      "IMAGING RESULTS",
      "PHYSICAL EXAM",
      "PRESCRIPTION",
      "APPOINTMENTS",
    ].map((heading) => ({
      title: heading,
      style: "Bullet points",
      hidden: (heading === "SUBJECTIVE" || heading === "OBJECTIVE" || heading === "ASSESSMENT" || heading === "PLAN") ? false : true,
      customInstructions: "",
      settingInstruction: false,
    }));
    const { error } = await supabase.from("consultationheadings").update({ headings: { headings: defaultHeadings }}).eq("userID", userDetails.id);
    if (error) {
      console.error("Error updating heading in database:", error.message);
      return;
    }
    setSections(defaultHeadings);
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5" sx={{ marginBottom: 3 }}>
          Sections Settings
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <Button variant="contained" onClick={() => setPopupTemplate(true)} sx={{ borderRadius: 30, backgroundColor: '#29C0BB', padding: '5px 10px', color: "#FFFFFF", cursor: 'pointer'}}>
            Add New Section
          </Button>
          <Button variant="contained" onClick={() => handleResetSection()} sx={{ borderRadius: 30, backgroundColor: '#29C0BB', padding: '5px 10px', color: "#FFFFFF", cursor: 'pointer'}}>
            Reset Sections
          </Button>
        </Stack>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sections">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              {sections.map((section, index) => (
                <Draggable
                  key={`draggable-${index}`}
                  draggableId={`draggable-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <Paper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        padding: 2,
                        backgroundColor: "#FFFFFF",
                        cursor: "grab",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        <Typography variant="h6">{section.title}</Typography>
                        <IconButton onClick={() => handleDeleteSection(index)} >
                          <DeleteIcon />
                        </IconButton>  
                      </Box>
                      <Divider sx={{ marginBottom: 2 }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 2,
                        }}
                      >
                        <Typography>Section Style</Typography>
                        <ToggleButtonGroup
                          value={section.style}
                          exclusive
                          onChange={(e, newStyle) =>
                            handleStyleChange(index, newStyle)
                          }
                          size="small"
                        >
                          <ToggleButton value="Bullet points" sx={{"&.Mui-selected": { backgroundColor: "#29C0BB", color: "#fff", "&:hover": { backgroundColor: "#29C0BB" }},}} >
                            Bullet points
                          </ToggleButton>
                          <ToggleButton sx={{"&.Mui-selected": { backgroundColor: "#29C0BB", color: "#fff", "&:hover": { backgroundColor: "#29C0BB"}},}} value="Paragraph">
                            Paragraph
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 2,
                        }}
                      >
                        <Typography>Custom Instructions</Typography>
                        <Stack direction="row" spacing={2} alignItems="center">
                        {
                          section.customInstructions || section.settingInstruction ? 
                            <TextField sx={{marginBottom: '16px'}} multiline value={section.customInstructions} onChange={(e) => handleAddInstruction(index, e.target.value)} fullWidth placeholder="Enter an instruction..." />
                          : 
                          <></>
                        }
                          <Button
                            variant="outlined"
                            sx={{borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}
                            size="small"
                            onClick={() => 
                              {
                                if(section.settingInstruction){
                                  setInstruction(index, false)
                                } else {
                                  setInstruction(index, true)
                                }
                              }
                            }
                          >
                            <Typography variant="button" sx={{color: '#FFFFFF'}}>{section.customInstructions || section.settingInstruction ? 'Save' : 'Add'}</Typography>
                          </Button>
                        </Stack>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Hide section by default</Typography>
                        <Switch
                          checked={section.hidden}
                          onChange={() => handleHideToggle(index)}
                          sx={{                          
                            "&.MuiSwitch-root .Mui-checked": {
                             color: "#29C0BB"
                            }
                           }}
                        />
                      </Box>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {
        isPopupTemplate && (
          <PopupMenu>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                <Typography variant="h5">Add a New Section</Typography>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                  <Box onClick={() => { handleAddNewHeading(); }} sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                      Save
                  </Box>
                  <Box onClick={() => {setPopupTemplate(false); setNewHeading(""); }} sx={{ borderRadius: 30, backgroundColor: '#F8F9FA', padding: '5px 10px', color: "#29C0BB", cursor: 'pointer'}}>
                    Close
                  </Box>
                </Stack>
              </Stack>
              <TextField multiline maxRows={10} label="Section Heading" value={newHeading}  onChange={(e) => setNewHeading(e.target.value) } fullWidth placeholder="Section Heading..." sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} />
          </PopupMenu>
          )
      }
    </Box>
  );
};

export default SectionsSettings;
