import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import { AlertProvider } from './alert/AlertContext.js';
import AlertPopup from './alert/AlertPopup.js';
import "react-big-calendar/lib/css/react-big-calendar.css";

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    allVariants: {
      color: '#000000',
    },
  },
  palette: {
    text: {
      primary: '#000000',
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#29C0BB",
          '&:hover': {
            backgroundColor: 'transparent',
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `2px solid #29C0BB`,
            },
          }
        }
      }
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       "& .MuiInputBase-input.Mui-disabled": {
    //         WebkitTextFillColor: "#000000",
    //       },
    //     },
    //   },
    // }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <AlertProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AlertPopup />
              <App />
            </ThemeProvider>
          </AlertProvider>
        </Router>
      </PersistGate>
    </Provider>
);
