import { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import UpdatePassword from './pages/Login/UpdatePassword';
import EmailVerify from './pages/Signup/EmailVerify';
import MedicalScribe from './pages/MedicalScribe/MedicalScribe';
import ConsultationSummary from './pages/MedicalScribe/MedicalScribeSummary';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setCurrentTab, setDrawerOpen } from './store.js';
import CreateProfile from './pages/Signup/CreateProfile';
import SettingsPage from './pages/Settings/Settings';
import Chats from './pages/Chats/Chats';
import Summarizer from './pages/Summarizer/Summarizer';
import KnowledgeBase from './pages/KnowledgeBase/KnowledgeBase';
import AgentList from './pages/Agents/AgentList';

const App = () => {
  const { isLoggedIn } = useSelector((state) => ({ isLoggedIn: state.globalState.isLoggedIn }), shallowEqual);
  const location = useLocation();
  const dispatch = useDispatch();
  const tabRoutes = {
    '/consultation': 'Scribe Assistant',
    '/consultationSummary': 'Scribe Assistant',
    // '/chats': 'Chats',
    // '/summarizer': 'Summarizer',
    // '/resources': 'Resource Library',
    // '/agents': 'AI Agent',
  }

  useEffect(() => {
    const tab = tabRoutes[location.pathname];
    dispatch(setCurrentTab(tab))
    dispatch(setDrawerOpen(false));
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/consultation" element={isLoggedIn ? <MedicalScribe /> : <Navigate to="/login" />} />
      <Route path="/consultationSummary" element={isLoggedIn ? <ConsultationSummary /> : <Navigate to="/login" />} />
      {/* <Route path="/chats" element={isLoggedIn ? <Chats /> : <Navigate to="/login" />} />
      <Route path="/summarizer" element={isLoggedIn ? <Summarizer /> : <Navigate to="/login" />} />
      <Route path="/resources" element={isLoggedIn ? <KnowledgeBase /> : <Navigate to="/login" />} />
      <Route path="/agents" element={isLoggedIn ? <AgentList /> : <Navigate to="/login" />} /> */}
      <Route path="/emailVerify" element={!isLoggedIn ? <EmailVerify /> : <Navigate to="/login" />} />
      <Route path="/updatePassword" element={!isLoggedIn ? <UpdatePassword /> : <Navigate to="/login" />} />
      <Route path="/createProfile" element={!isLoggedIn ? <CreateProfile /> : <Navigate to="/login" />} />
      <Route path="/settings" element={isLoggedIn ? <SettingsPage /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
