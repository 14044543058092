import React, { useState } from 'react';
import { supabase } from '../../supabaseClient.js';
import Grid from '@mui/material/Grid2';
import { Container, IconButton, Typography, TextField, Stack, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TikcareLogo } from '../../assets/svg/TikcareLogo.svg';
import useAlert from '../../alert/useAlert.js';

const UpdatePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const handleResetPassword = async () => {
    if(passwordError || confirmPasswordError || !newPassword || !confirmPassword) {
      setAlert('Please enter a valid password', 'error');
      return;
    }
    if(newPassword !== confirmPassword) {
      setAlert('Passwords do not match', 'error');
      return;
    }
    const {error } = await supabase.auth.updateUser({ password: newPassword })
    if(error) {
        setAlert(error.message, 'error');
    }
    else {
      setAlert("Password updated successfully!", 'success');
      navigate('/');
    }
  };

  const validatePassword = (password) => {
    // Regex for at least one letter, one special character, and minimum 6 characters
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    if (!strongPasswordRegex.test(password)) {
      setPasswordError('Password must contain at least 6 characters, one letter, and one special character.');
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = (password) => {
    // Regex for at least one letter, one special character, and minimum 6 characters
    const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    if (!strongPasswordRegex.test(password)) {
      setConfirmPasswordError('Password must contain at least 6 characters, one letter, and one special character.');
    } else {
      setConfirmPasswordError('');
    }
  };

  return (
    <Container disableGutters maxWidth={false}> 
      <Grid container spacing={2} sx={{ minHeight: '100vh', minWidth: '100vw', marginTop: -3 }}>
      <Grid size={12} sx={{ backgroundColor: '#B9F4F2', height: '90px', padding: '15px 10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <TikcareLogo onClick={() => { navigate('/'); }} style={{cursor: 'pointer'}}/>
        </Grid>
        <Grid size={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
        <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" style={{backgroundColor: '#f5f5f5', padding: '30px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
          <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '20px'}}>Reset Password</Typography>
          <Typography variant="body1"> Remember your password? <span onClick={() => navigate('/') } style={{ cursor: 'pointer', color: '#29C0BB' }}>Login</span> </Typography>
          <TextField
            required
            fullWidth
            label="Create a password"
            variant="outlined"
            focused
            value={newPassword}
            onChange={(e) => {setNewPassword(e.target.value); validatePassword(e.target.value); }}
            placeholder="Create a password" 
            type={showPassword ? "text" : "password"}
            sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
            error={(passwordSubmitted && !newPassword) || passwordError}
            helperText={((passwordSubmitted && !newPassword) || passwordError) ? passwordError ?? "Enter a password" : ""}
            slotProps={{
              input: {
                endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
                ),
              }
            }}
          />
          <TextField
            required
            fullWidth
            label="Confirm password"
            variant="outlined"
            focused
            value={confirmPassword}
            onChange={(e) => { setConfirmPassword(e.target.value); validateConfirmPassword(e.target.value);} }
            placeholder="Confirm password" 
            type={showConfirmPassword ? "text" : "password"}
            sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
            error={(passwordSubmitted && !confirmPassword)  || confirmPasswordError}
            helperText={((passwordSubmitted && !confirmPassword)  || confirmPasswordError) ? confirmPasswordError ?? "Enter a password" : ""}
            slotProps={{
              input: {
                endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
                ),
              }
            }}
          />
          <Button onClick={() => { setPasswordSubmitted(true); handleResetPassword();  }} variant="outlined" sx={{width: '100%', height: '50px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
            <Typography variant="button" sx={{color: '#FFFFFF', fontSize: '15px'}}>Reset Password</Typography>
          </Button>
        </Stack>
      </Grid> 
      </Grid>
    </Container>
  );
};

export default UpdatePassword;

