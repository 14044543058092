import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, MenuItem, IconButton, Stack } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { supabase } from '../../../supabaseClient.js';
import Select from 'react-select';
import useAlert from '../../../alert/useAlert.js';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const MEDICAL_OPTIONS = [
  { value: 'ICD', label: 'ICD', bgcolor: '#FEF0E0', textColor: '#F69C32'},
//   { value: 'CPT', label: 'CPT', bgcolor: '#EFE3FF', textColor: '#A82EE1'},
//   { value: 'ICPC', label: 'ICPC', bgcolor: '#D9E8FF', textColor: '#0066FF'},
];

export default function MedicalCoding({ consultationId, summary, addedCodes, setAddedCodes, fetchAddedCodes, suggestedCodes, codeLoading, fetchSuggestedCodes }) {
    const [selectedOption, setSelectedOption] = useState(MEDICAL_OPTIONS[0]);
    const [availableCodes, setAvailableCodes] = useState([]);
    const [selectedCode, setSelectedCode] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { setAlert } = useAlert();
    const hasMounted = useRef(false);

    useEffect(() => {
        if (hasMounted.current) return;
        fetchAddedCodes(consultationId);
        fetchSuggestedCodes(summary);
        hasMounted.current = true;
    }, [ consultationId ]);

    useEffect(() => {
        const fetchCodes = async () => {
            if (!searchTerm) return;
      
            const url = `https://clinicaltables.nlm.nih.gov/api/icd11_codes/v3/search`;
            const params = {
              terms: searchTerm,
              sf: 'code,title',
              df: 'code,title',
              maxList: 500,
            };
      
            try {
              const response = await axios.get(url, { params });
              const codesData = response.data;
      
              const updatedAvailableCodes = codesData[3].map((item) => ({
                value: item[0],
                label: `${item[0]}\n${item[1]}`,
                description: item[1],
                type: 'ICD',
              }));
      
              setAvailableCodes(updatedAvailableCodes);
            } catch (error) {
              console.error('Error fetching codes:', error);
            }
          };
      
          fetchCodes();
    }, [searchTerm]);


    const handleAddCode = async (code) => {
        if(!code) return;

        const isDuplicate = addedCodes.some(
            (addedCode) => addedCode.type === code.type && addedCode.value === code.value
          );

        if (isDuplicate) {
            setAlert('Duplicate code! This code is already added.', 'warning');
            return;
        }
        const updatedCodes = [...addedCodes, code];
        setAddedCodes(updatedCodes);
        setSelectedCode(code);

        // Save to Supabase
        let { error } = await supabase
          .from('consultationmodel')
          .update({ codes: updatedCodes.map((c) => `${c.value}-${c.description}-${c.type}`) })
          .eq('id', consultationId);
        if (error){
            console.error(error);
        }
        else{
            setAlert('Code added successfully!', 'success');
        }
    };

    // Handle removing a code
    const handleRemoveCode = async (code) => {
        const updatedCodes = addedCodes.filter((c) => c.value !== code.value);
        setAddedCodes(updatedCodes);
        // Update Supabase
        let { error } = await supabase
          .from('consultationmodel')
          .update({ codes: updatedCodes.map((c) => `${c.value}-${c.description}-${c.type}`) })
          .eq('id', consultationId);
        if (error){
            console.error(error);
        }
        else{
            setAlert('Code removed successfully!', 'success');
        }
    };

    const handleInputChange = (inputValue) => {
        setSearchTerm(inputValue);
    };

    return (
    <Box sx={{ width: '100%' }}>
        <Box sx={{ gap: 2, mb: 2, display: 'flex', alignItems: 'center', border: '1px solid #E2E2E2', borderRadius: 2, padding: '10px'}}>
            <Box
                sx={{
                backgroundColor: selectedOption.bgcolor,
                borderRadius: 2,
                width: '120px',
                fontWeight: 'bold',
                padding: '0px 10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}
            >
                <TextField
                    select
                    fullWidth
                    value={selectedOption.value}
                    onChange={(e) => {
                        setSelectedOption( 
                            MEDICAL_OPTIONS.find((option) => option.value === e.target.value)
                        );
                        setSelectedCode(null);
                        }
                    }
                    variant="standard"
                    slotProps={{ 
                        input: {
                            disableUnderline: true
                        }
                    }}
                    sx={{
                        borderBottom: 0,
                        textAlign: 'center',    
                        margin: '5px 0px',
                    }}
                    >
                    {MEDICAL_OPTIONS.map((option) => (
                        <MenuItem 
                            key={option.value} 
                            value={option.value} 
                            style={{ 
                                padding: '0px', 
                                height: '40px', 
                                width: '90px',
                            }}
                            >
                            <Typography 
                                variant="body1" 
                                sx={{ 
                                color: option.textColor, 
                                backgroundColor: option.bgcolor, 
                                fontWeight: 'bold', 
                                width: '100%', 
                                height: '100%', 
                                textAlign: 'center', 
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'center',
                                }}
                            >
                                {option.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Select
                value={selectedCode !== null ? selectedCode : ''}
                onChange={handleAddCode}
                onInputChange={handleInputChange}
                options={availableCodes}
                placeholder="Search Code"
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                styles={{   
                    control: base => ({
                        ...base,
                        border: 0,
                        boxShadow: 'none',
                    }),
                    container: (base) => ({
                        ...base,
                        width: '100%',
                        whiteSpace: 'pre-wrap',
                    }),
                }}
            />
        </Box>

        {/* Added Codes Section */}
        <Typography variant="h6" sx={{ mb: 1 }}>
        Added
        </Typography>
        <Box sx={{ mb: 3 }}>
        {
        addedCodes.length === 0 ? (
            <Typography variant="body2" color="text.secondary">
            No codes added yet.
            </Typography>
        ) : (
            addedCodes.map((code) => (
            <Box
                key={code.value}
                sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 1,
                padding: 1,
                borderRadius: 1,
                backgroundColor: '#f9f9f9',
                }}
            >
                <Box sx={{ flex: 1 }}>
                <Typography variant="body1">{code.value}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {code.description}
                </Typography>
                </Box>
                <Box
                sx={{
                    backgroundColor: MEDICAL_OPTIONS.find(
                    (option) => option.value === code.type
                    )?.bgcolor,
                    color: MEDICAL_OPTIONS.find(
                    (option) => option.value === code.type
                    )?.textColor,
                    borderRadius: 1,
                    padding: '4px 8px',
                }}
                >
                {code.type}
                </Box>
                <IconButton onClick={() => handleRemoveCode(code)}>
                <CloseIcon />
                </IconButton>
            </Box>
            ))
        )}
        </Box>

        {/* Suggested Codes Section */}
        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{mb: 1}}>
            <Typography variant="h6" sx={{ mr: 1, }}>
                Suggested
            </Typography>
            <IconButton onClick={() => { fetchSuggestedCodes(summary);}}>
                <RotateLeftIcon />
            </IconButton>
        </Stack>
        <Box>
        {codeLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CircularProgress size={24} />
            </Box>
        ) : 
        suggestedCodes.length === 0 ? (
            <Typography variant="body2" color="text.secondary">
            No suggested codes available.
            </Typography>
        ) : (
            suggestedCodes.map((code) => (
            <Box
                key={code.value}
                sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 1,
                padding: 1,
                borderRadius: 1,
                backgroundColor: '#f9f9f9',
                }}
            >
                <Box sx={{ flex: 1 }}>
                <Typography variant="body1">{code.value}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {code.description}
                </Typography>
                </Box>
                <Box
                sx={{
                    backgroundColor: MEDICAL_OPTIONS.find(
                    (option) => option.value === code.type
                    ).bgcolor,
                    color: MEDICAL_OPTIONS.find(
                    (option) => option.value === code.type
                    ).textColor,
                    borderRadius: 1,
                    padding: '4px 8px',
                    width: '60px',
                    textAlign: 'center',
                }}
                >
                {code.type}
                </Box>
                <IconButton onClick={() => handleAddCode(code)}>
                <AddIcon />
                </IconButton>
            </Box>
            ))
        )}
        </Box>
    </Box>
    );
}
